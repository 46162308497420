import { call, put, race, select, take, takeLatest } from "redux-saga/effects";
import { AppState } from "../..";
import config from "../../../config";
import querystring from 'querystring'
import api from "../../../api";
import { ThenReturnType } from "../../types";
import { paymentAppointmentTypeFailureAction, paymentAppointmentTypeRequestAction, paymentAppointmentTypeSuccessAction } from "./payment.appointmentType.actions";
import { paymentCombinedAppointmentsFailureAction, paymentCombinedAppointmentsRequestAction, paymentCombinedAppointmentsSuccessAction } from "./payment.combinedAppointments.actions";
import { takeLeadingPattern } from "../../utils";
import { PaymentAction } from "./payment.types";
import { paymentParamsMakePaymentAction, paymentParamsSetAction } from "./payment.params.actions";

export const selectPaymentParams = (state: AppState) => state.payment.params

export const selectUserToken = (state: AppState) => state.user.token

export function* paymentParamsMakePayment(action: any) {
  const params: ReturnType<typeof selectPaymentParams> = yield select(selectPaymentParams)
  const {
    appointment_id: direction_service_id,
    price,
    bonusCode: bonus_code,
    bonusPoints: bonus_points,
  } = params
  const auth_token: ReturnType<typeof selectUserToken> = yield select(selectUserToken)
  const amount = (price as number) * 100 - (bonus_points ?? 0) * 100
  const getLocation = () => window.location.href;
  const query = { direction_service_id, amount, bonus_code, bonus_points, auth_token, url: getLocation() }
  if (!bonus_code) {
    delete query.bonus_code
    delete query.bonus_points
    // @ts-ignore
    delete query.auth_token
  }
  const url = `${config.api.telemedURL}/api/v2/ecom_create?${querystring.stringify(query)}`
  // @ts-ignore
  window.location = url
}

export function* paymentAppointmentTypeRequestSaga(action: any) {
  const res: ThenReturnType<typeof api.payment.getAppointmentType> = yield call(api.payment.getAppointmentType, action.params)
  if (res.error) {
    yield put(paymentAppointmentTypeFailureAction())
  } else {
    yield put(paymentAppointmentTypeSuccessAction(res.data))
  }
}

export function* paymentCombinedAppointmentsRequestSaga(action: any) {
  const res: ThenReturnType<typeof api.payment.getCombinedAppointments> = yield call(api.payment.getCombinedAppointments, action.params)
  if (res.error) {
    yield put(paymentCombinedAppointmentsFailureAction())
  } else {
    yield put(paymentCombinedAppointmentsSuccessAction(res.data))
  }
}

// export const selectAppointmentType = (state: AppState) => state.payment.appointmentType.type
// export const selectCombinedAppointments = (state: AppState) => state.payment.combinedAppointments

// export function* paymentStartSaga(action: any) {
//   const { appointment_id, medic_user_id } = action.params
//   yield put(paymentCombinedAppointmentsRequestAction({ appointment_id, medic_user_id }))
//   const returnAction: { type: string } = yield take(['PAYMENT_COMBINED_APPOINTMENT_FAILURE', 'PAYMENT_COMBINED_APPOINTMENT_SUCCESS'])
//   if (returnAction.type == 'PAYMENT_COMBINED_APPOINTMENTS_FAILURE') {
//     return
//   }
//   const combined: ReturnType<typeof selectCombinedAppointments> = yield select(selectCombinedAppointments)

//   yield put(paymentAppointmentTypeRequestAction({ appointment_id, medic_user_id }))
//   const res: { type: string } = yield take(['PAYMENT_APPOINTMENT_TYPE_FAILURE', 'PAYMENT_APPOINTMENT_TYPE_SUCCESS'])
//   if (res.type == 'PAYMENT_APPOINTMENT_TYPE_FAILURE') {
//     return
//   }
//   const type: ReturnType<typeof selectAppointmentType> = yield select(selectAppointmentType)
//   if (!type.is_combined) {
//     yield put(paymentParamsMakePaymentAction())
//     return
//   }
//   if (type.is_partial) {
//     yield put(paymentParamsSetAction({ bonusBlocked: true }))
//   }
// }

export default function* watchPayment() {
  yield takeLatest('PAYMENT_PARAMS_MAKE_PAYMENT', paymentParamsMakePayment)
  yield takeLeadingPattern<PaymentAction['type']>('PAYMENT_APPOINTMENT_TYPE_REQUEST', paymentAppointmentTypeRequestSaga, paymentAppointmentTypeFailureAction)
  yield takeLeadingPattern<PaymentAction['type']>('PAYMENT_COMBINED_APPOINTMENTS_REQUEST', paymentCombinedAppointmentsRequestSaga, paymentCombinedAppointmentsFailureAction)
}